* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
:root {
  --blue-color: #0267ff;
  --white-color: #fff;
  --blackshade-color: #030303;
  --active-color: #0f823f;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
h3 {
  font-size: 28px;
  font-weight: 600;
  font-family: "Playfair Display";
  color: var(--blackshade-color);
}
.active {
  color: var(--active-color) !important;
}
.button {
  padding: 10px 12px;
  outline: none;
  border: none;
  border-radius: 5px;
  color: var(--white-color);
  background-color: var(--blue-color);
  font-size: 18px;
  cursor: pointer;
}
.button:hover {
  transition: all 0.5s ease-in-out 0s;
  color: var(--blue-color);
  background-color: var(--white-color);
  border: 1px solid var(--blue-color);
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.containerGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr; /* 1 column by default */
  gap: 16px; /* Adjust the gap between items as needed */
}

@media (min-width: 768px) {
  .containerGrid {
    grid-template-columns: 1fr 1fr; /* 2 columns for tablets */
  }
}

@media (min-width: 1024px) {
  .containerGrid {
    grid-template-columns: 1fr 1fr 1fr; /* 3 columns for desktops */
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.breadcrums {
  width: 100%;
  padding-left: 5%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  font-family: "Playfair Display";
}
.breadcrums > a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.68);
}
.breadcrums > p {
  text-decoration: none;
  color: var(--blackshade-color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loadercss {
  height: 50rem;
  width: 100%;
}
.flag {
  width: 200px;
  margin: 100px auto;
}
.hand {
  height: 35px;
}
.orange {
  width: 200px;
  height: 40px;
  background-color: #ff9933;
}
.white {
  width: 200px;
  height: 40px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.green {
  width: 200px;
  height: 40px;
  background-color: #128807;
}
.circle {
  width: 35px;
  height: 35px;
  border: 2px solid #000080;
  border-radius: 50%;
  margin: 0px auto;
  -webkit-animation: circle 5s infinite;
  animation: circle 10s linear 2s infinite;
}
.circle:after {
  content: "";
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #00080;
}
.bar {
  position: absolute;
  display: inline-block;
  height: 35px;
  width: 2px;
  background: #000080;
  left: 47%;
}
.bar:nth-child(1) {
  -ms-transform: rotate(15deg);
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
}
.bar:nth-child(2) {
  -ms-transform: rotate(30deg);
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.bar:nth-child(3) {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.bar:nth-child(4) {
  -ms-transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}
.bar:nth-child(5) {
  -ms-transform: rotate(75deg);
  -webkit-transform: rotate(75deg);
  transform: rotate(75deg);
}
.bar:nth-child(6) {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.bar:nth-child(7) {
  -ms-transform: rotate(105deg);
  -webkit-transform: rotate(105deg);
  transform: rotate(105deg);
}
.bar:nth-child(8) {
  -ms-transform: rotate(120deg);
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
}
.bar:nth-child(9) {
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.bar:nth-child(10) {
  -ms-transform: rotate(150deg);
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}
.bar:nth-child(11) {
  -ms-transform: rotate(165deg);
  -webkit-transform: rotate(165deg);
  transform: rotate(165deg);
}

@-webkit-keyframes circle {
  from {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.flagwave {
  width: 200px;
  height: 120px;
  margin: -220px auto;
  z-index: 2;

  background-image: -webkit-gradient(
      linear,
      0% 30%,
      50% top,
      color-stop(0%, rgba(255, 255, 255, 0)),
      color-stop(40%, rgba(255, 255, 255, 0)),
      color-stop(90%, rgba(255, 255, 255, 0.3)),
      color-stop(100%, rgba(255, 255, 255, 0))
    ),
    -webkit-gradient(linear, 50% 30%, 100% top, color-stop(0%, rgba(224, 225, 225, 0)), color-stop(25%, rgba(225, 225, 225, 0.3)), color-stop(50%, rgba(224, 225, 225, 0)), color-stop(80%, rgba(224, 225, 225, 0.3)), color-stop(100%, rgba(224, 225, 225, 0)));

  background-size: 200%;
  background-position: right;

  -webkit-animation-name: flagwave;
  -webkit-animation-duration: 10s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes flagwave {
  0% {
    background-position: -400px 0px, -400px 0px, -400px 0px, -400px 0px;
  }

  100% {
    background-position: 800px 0px, 800px 0px, 800px 0px, 800px 0px;
  }
}
#loader {
  color: #000;
  background: red;
  background: linear-gradient(to bottom right, #ff9933, #ffffff, #128807);
  height: 20px;
  width: 100px;
  animation-timing-function: linear;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-name: load;
}
@keyframes load {
  0% {
    width: 20px;
  }
  25% {
    width: 50px;
  }
  50% {
    width: 100px;
  }
  75% {
    width: 150px;
  }
  100% {
    width: 200px;
  }
}
.loaderContainer {
  margin: 10% auto;
}
