@media only screen and (max-width: 1200px) {
  .homebanner_left > h1 {
    font-size: 55px !important;
  }
  .homebanner_right > img {
    width: 100% !important;
  }
  .homebanner_left > h1 {
    font-size: 55px !important;
  }
}

@media only screen and (max-width: 1030px) {
  .homebanner_left > h1 {
    font-size: 40px !important;
  }
  .homebanner_right > img {
    width: 100% !important;
  }
}
@media only screen and (max-width: 800px) {
  .homebanner_right > img {
    width: 100% !important;
  }
}

@media only screen and (max-width: 750px) {
  .homebanner_right > img {
    width: 104% !important;
  }
}
@media only screen and (max-width: 650px) {
  .homebanner {
    flex-direction: column !important;
  }

  .homebanner_left {
    flex: 1 0 !important;
  }
  .homebanner_left > p {
    margin-top: 10px;
  }
  .homebanner_left > h1 {
    font-size: 30px !important;
    line-height: 3rem !important;
  }
  .homebanner_right > img {
    width: 100% !important;
  }
  .homebanner_right {
    height: 55% !important;
  }
}
/* @media only screen and (max-width: 500px) {
  .homebanner {
    height: 90vh !important;
  }
}

@media only screen and (max-width: 400px) {
  .homebanner {
    height: 80vh !important;
  }
}
@media only screen and (max-width: 375px) {
  .homebanner {
    height: 78vh !important;
  }
}

@media only screen and (max-width: 300px) {
  .homebanner {
    height: 70vh !important;
  }
} */

.homebanner {
  width: 90%;
  /* height: 80vh; */
  border-radius: 5px;
  background: url("../../../Assest/Images/bg\ img.png") center/cover no-repeat,
    rgba(0, 0, 0, 0.9);
  mix-blend-mode: luminosity;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 30px 0;
  width: 90%;
}
.overlay {
  mix-blend-mode: color;
}
.homebanner_left {
  flex: 0.4;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 5%;
  gap: 20px;
}
.homebanner_left > p {
  gap: 10px;
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  color: var(--white-color);
}
.homebanner_left > h1 {
  font-family: "Playfair Display";
  font-size: 66px;
  font-weight: 700;
  line-height: 5rem;
  color: var(--white-color);
}
.homebanner_left > h1 > span {
  color: var(--blue-color);
}
.homebanner_left > p > span {
  display: flex;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--blue-color);
}
.homebanner_right {
  flex: 0.55;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.homebanner_right > img {
  /* height: 100%; */
  width: 75%;
  object-fit: contain;
}
