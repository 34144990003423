@media screen and (max-width: 600px) {
  .footer_container > p {
    padding-left: 22px;
    text-align: center;
  }
}

footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer_container {
  width: 90%;
  height: 35vh;
  background-color: #0e0e0e;
  border-top-left-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  color: var(--white-color);
  border-radius: 5px;
}
.footer_container > ul {
  display: flex;
  gap: 20px;
}
.footer_container > ul > li {
  list-style: none;
  cursor: pointer;
}
.links > li > a {
  text-decoration: none;
  list-style: none;
  color: var(--white-color);
}
