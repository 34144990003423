.ourmissioncard {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  color: var(--white-color);
  padding: 20px;
  gap: 10px;
}

.ourmissioncard > img {
  height: 40px;
  object-fit: contain;
  z-index: 11;
}
.ourmissioncard > h6 {
  font-size: 20px;
  font-weight: 600;
  z-index: 11;
}
.ourmissioncard > p {
  font-size: 15px;
  z-index: 11;
}
