@media screen and (max-width: 1000px) {
  .ourmissiom_cards {
    justify-content: center !important;
  }
}

@media screen and (max-width: 600px) {
  .ourmissiom_p_second {
    width: 90% !important;
  }
  .ourmissiom_cards {
    justify-content: center !important;
  }
}

.ourmissiom {
  width: 100%;
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  /* box-shadow: 0px 0px 5px 0px #888888; */
  border-radius: 15px;
}
.ourmissiom_p_first {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
  color: var(--blackshade-color);
  margin-top: 12px;
}
.ourmissiom_p_first > span {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--blue-color);
}
.ourmissiom > h3 > span {
  color: var(--blue-color);
}
.ourmissiom_p_second {
  width: 40%;
}
.ourmissiom_cards {
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 40px;
  width: 90%;
}
